<template>
  <div class="index">
    <ul class="menu">
      <li @click="openMenu('account')">我的资料</li>
      <li @click="openMenu('accountTruthName')">实名认证</li>
      <li @click="openMenu('accountAddress')">我的地址</li>
      <li @click="openMenu('accountOrder')">我的订单</li>
      <li @click="openMenu('accountCart')">我的购物车</li>
      <li @click="openMenu('accountTuijian')">我的推荐</li>
    </ul>
    <div class="main">
      <div class="mainTitle">
        <span>我的订单</span>
      </div>
      <div class="menuTitle">
        <ul class="menuTitleBox">
          <li :class="curIndex == 0 ? 'actived' : ''" @click="getOrders(0)">
            待支付
          </li>
          <li :class="curIndex == 1 ? 'actived' : ''" @click="getOrders(1)">
            待发货
          </li>
          <li :class="curIndex == 2 ? 'actived' : ''" @click="getOrders(2)">
            待收货
          </li>
          <li :class="curIndex == 4 ? 'actived' : ''" @click="getOrders(4)">
            已完成
          </li>
          <li :class="curIndex == -3 ? 'actived' : ''" @click="getOrders(-3)">
            售后/退款
          </li>
        </ul>
        <el-input
          v-model="keywords"
          sizi="medium"
          style="width: 220px; height: 40px"
        >
          <el-button
            slot="append"
            sizi="medium"
            icon="el-icon-search"
          ></el-button>
        </el-input>
      </div>
      <div class="orders" v-if="orderList.length">
        <div
          :class="getColor(curIndex)"
          v-for="(item, index) in orderList"
          :key="index"
        >
          <div
            class="orderStatus"
            v-if="curIndex == 0"
            style="color: orangered"
          >
            等待付款
            <span
              style="color: #333; fontsize: 14px"
              v-if="computerLastDate(item.createTime) !== '订单已失效'"
              >请在<span style="color: orangered">{{
                computerLastDate(item.createTime)
              }}</span
              >内支付，逾期订单将自动取消</span
            >
            <span v-else
              ><span style="color: orangered">{{
                computerLastDate(item.createTime)
              }}</span></span
            >
          </div>
          <div class="orderStatus" v-if="curIndex == 1">
            <span style="color: #4aed77">已付款待发货</span>
          </div>
          <div class="orderStatus" v-if="curIndex == 2">
            <span style="color: #4aed77">{{
              computerLastReviceDate(item.payTime)
            }}</span>
          </div>
          <div class="orderStatus" v-if="curIndex == 4">
            <span style="color: #4aed77">已完成</span>
          </div>
          <div class="orderStatus" v-if="curIndex == -3">
            <span style="color: #4aed77">售后/退款</span>
          </div>
          <div class="orderInfo">
            <ul class="left">
              <li>{{ item.createTime }}</li>
              <li>{{ item.realName }}</li>
              <li>订单号：{{ item.orderId }}</li>
              <li v-if="item.payType == 'brokerage'">奖励金支付</li>
              <li v-else-if="item.payType == 'yue'">余额支付</li>
              <li v-else>微信(native)</li>
            </ul>
            <div class="right">
              实付金额：<span>{{ item.payPrice }}</span
              >元
            </div>
          </div>
          <div class="goods">
            <div class="goodsItemBox">
              <div
                class="goodsItem"
                v-for="items in item.orderInfoList"
                :key="items.id"
              >
                <img :src="items.image" alt="" />
                <div class="images">
                  <div>{{ items.storeName }}</div>
                  <div>{{ items.price }}元 X {{ items.cartNum }}</div>
                  <div>{{ items.sku }}</div>
                </div>
              </div>
            </div>

            <div class="actions">
              <el-button
                size="small"
                type="warning"
                @click="payNow(item)"
                v-if="curIndex == 0"
                >立即支付</el-button
              >
              <el-button size="small" @click="orderDetail(item.orderId,item.judgeStatus)"
                >订单详情</el-button
              >
              <el-button
                size="small"
                @click="cancelOrder(item)"
                v-if="curIndex == 0"
                >取消订单</el-button
              >
              <el-button
                v-if="curIndex == 1"
                size="small"
                @click="applyBack(item)"
                style="color: skyblue"
                >申请退款</el-button
              >
              <el-button
                size="small"
                v-if="curIndex == 2"
                v-clipboard:error="onError"
                v-clipboard:copy="item.deliveryId"
                v-clipboard:success="onCopy"
                >查看物流</el-button
              >
              <el-button
                size="small"
                type="danger"
                v-if="curIndex == 2"
                @click="surereceive(item)"
                >确认收货</el-button
              >
            </div>
          </div>
        </div>
        <el-pagination
          style="textalign: center; marginbottom: 20px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <div v-else class="noData">
        <div>
          您还没有订单，快去挑选喜欢的商品吧！<el-link
            @click="toMall"
            type="primary"
            >前往商城首页</el-link
          >
        </div>
      </div>
      <pay-dialog
        @finshed="finshed"
        :show="showPay"
        :Image="payUrl"
        :orderNo="orderNos"
        :type="2"
        :price="chooseMoney"
        :totalInfo="orderinfosVal"
      />
      <el-dialog
        title="订单评价"
        :visible.sync="showComment"
        :close-on-click-modal="false"
        @close="canceComment"
        custom-class="dialog-minwidth"
        :modal-append-to-body="false"
        width="700px"
      >
        <div style="padding: 0 10px 40px">
          <div
            class="goodsItem"
            v-for="items in currentGoods.orderInfoList"
            :key="items.id"
          >
            <img :src="items.image" alt="" />
            <div class="images">
              <div class="imagesItem">
                <span>{{ items.storeName }}</span
                ><span>{{ items.price }}元</span>
              </div>
              <div class="imagesItem">
                <span>{{ items.sku ? items.sku : "默认" }}</span
                ><span>X {{ items.cartNum }}</span>
              </div>
            </div>
          </div>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="120px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="活动名称："
              prop="productScore"
              style="lineheight: 40px"
            >
              <el-rate
                v-model="ruleForm.productScore"
                :colors="colors"
              ></el-rate>
            </el-form-item>
            <el-form-item label="活动名称：" prop="serviceScore">
              <el-rate
                v-model="ruleForm.serviceScore"
                :colors="colors"
              ></el-rate>
            </el-form-item>
            <el-form-item label="活动名称：" prop="comment">
              <el-input type="textarea" v-model="ruleForm.comment"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="medium">取 消</el-button>
          <el-button type="primary" size="medium" :loading="isSaveLoading"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { apiOrderList } from "@/api/goods";
import PayDialog from "@/components/PayDialog.vue";

export default {
  name: "basePAge",
  data() {
    return {
      info: {},
      keywords: "",
      curIndex: 0,
      page: 1,
      limit: 20,
      total: 0,
      orderList: [],
      userName: "",
      showPay: false,
      orderinfosVal: "",
      payUrl: "",
      orderNos: "",

      orderNo: "",
      chooseMoney: "",
      //商品评价
      showComment: false,
      currentGoods: {},
      ruleForm: {
        productScore: 0,
        serviceScore: 0,
        pics: "",
        comment: "",
      },
      rules: {
        productScore: [
          { required: true, message: "请选择评级", trigger: "change" },
        ],
        serviceScore: [
          { required: true, message: "请选择评级", trigger: "change" },
        ],
        comment: [
          { required: true, message: "请输入您的评价", trigger: "blur" },
          { min: 5, message: "至少输入5个字", trigger: "blur" },
        ],
      },
      isSaveLoading: false,
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
    };
  },
  components: { PayDialog },

  mounted() {
    this.page = 1;
    this.limit = 20;
    this.getOrderList(0);
    this.userName = JSON.parse(localStorage.getItem("userInfo")).nickname;
  },
  methods: {
    onCopy() {
      this.$message({
        message: "快递单号已复制成功，请在百度查看物流信息！",
        type: "success",
      });
    },
    // 复制失败
    onError() {
      this.$message({
        message: "复制失败！",
        type: "error",
      });
    },
    //计算订单制度时间
    computerLastDate(date) {
      let orderDate = new Date(date).getTime();
      let curDate = new Date().getTime();
      let seconds = parseInt((curDate - orderDate) / 1000);
      if (seconds > 7200) {
        return "订单已失效";
      } else {
        let h = parseInt((7200 - seconds) / 3600);
        let s = (7200 - seconds) % 60;
        if (7200 - seconds > 3600) {
          let m = parseInt((7200 - seconds - 3600) / 60);
          return `0${h}:${m > 10 ? m : "0" + m}:${s > 10 ? s : "0" + s}`;
        } else {
          let m = parseInt((7200 - seconds) / 60);
          return `0${h}:${m > 10 ? m : "0" + m}:${s > 10 ? s : "0" + s}`;
        }
      }
    },
    //计算订单制度时间
    computerLastReviceDate(date) {
      let orderDate = new Date(date).getTime();
      let curDate = new Date().getTime();
      let seconds = 60 * 60 * 24 * 10 - parseInt((curDate - orderDate) / 1000);
      if (seconds > 60 * 60 * 24) {
        let days = parseInt(seconds / (60 * 60 * 24));
        let lastSeconds = seconds - 60 * 60 * 24 * days;
        let h = parseInt(lastSeconds / 3600);
        let s = lastSeconds % 60;

        if (lastSeconds > 3600) {
          let m = parseInt((lastSeconds - 3600 * h) / 60);
          return `${days}天${h}小时${m}分${s}秒后订单将自动收货`;
        } else {
          let m = parseInt(lastSeconds / 60);
          return `${days}天${h}小时${m}分${s}秒后订单将自动收货`;
        }
      } else {
        let h = parseInt(seconds / 3600);
        let s = seconds % 60;
        if (seconds > 3600) {
          let m = parseInt((seconds - 3600) / 60);
          return `${h}小时${m}分${s}秒后订单将自动收货`;
        } else {
          let m = parseInt(seconds / 60);
          return `${h}小时${m}分${s}秒后订单将自动收货`;
        }
      }
    },
    getColor(index) {
      if (index == 0) {
        return "orderItem pinks";
      } else if (index == 1 || index == 2) {
        return "orderItem greens";
      } else {
        return "orderItem";
      }
    },
    finshed(index) {
      this.showPay = false;
      if (index == 1) {
        this.$message.warning({
          message: "订单支付超时",
          duration: 3000,
        });
      } else if (index == 3) {
        this.$message.warning({
          message: "取消支付",
          duration: 3000,
        });
      } else {
        this.$router.replace({ name: "accountOrder" });
        this.$message.success({
          message: "订单支付完成",
          duration: 3000,
        });
      }
    },
    openMenu(index) {
      localStorage.setItem("accountIndex", "accountOrder");
      this.$router.push({ name: index });
    },
    getOrders(index) {
      this.curIndex = index;
      this.getOrderList(index);
    },
    // 获取订单列表
    getOrderList(index) {
      this.curIndex = index;
      apiOrderList({ limit: this.limit, page: this.page, type: index }).then(
        (res) => {
          console.log(res);
          if (res.code == 200) {
            this.orderList = res.data.list;
            this.total = res.data.total;
          } else {
            this.orderList = [];
          }
        }
      );
    },
    handleSizeChange(val) {
      this.limit = val;
      this.getOrderList(this.curIndex);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getOrderList(this.curIndex);
    },
    toMall() {
      this.$router.push({ name: "mall" });
      this.$store.commit("SET_INFO", ["menuIndex", "mall"]);
    },
    //订单详情
    orderDetail(id,judgeStatus) {
      this.$router.push({
        name: "orderDetail",
        query: { index: this.curIndex, id,judgeStatus },
      });
    },
    //取消订单
    cancelOrder(item) {
      this.$confirm("此操作将取消该订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let formdata = new FormData();
          formdata.append("id", item.id);
          this.https.post("/api/front/order/cancel", formdata).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "订单已取消!",
              });
              this.getOrderList(this.curIndex);
            } else {
              this.$message({
                type: "warning",
                message: res.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "订单已取消",
          });
          this.getOrderList(this.curIndex);
        });
    },
    //立即支付
    payNow(item) {
      console.log(item);
      let params1 = {
        orderNo: item.orderId,
        payChannel: "native",
        payType: "weixin",
        scene: 0,
      };
      this.https.post("api/front/pay/payment", params1).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.showPay = true;
          this.orderinfosVal = item;
          this.chooseMoney = item.payPrice;
          this.payUrl = res.data.data.qrCodeImageBase64;
          this.orderNos = res.data.data.orderNo;
        } else {
          this.$message.warning({
            message: res.message,
            duration: 3000,
          });
        }
      });
    },
    //申请退款
    applyBack(item) {
      this.$confirm("您正在申请退款并取消订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.https
            .get(`/api/front/order/apply/refund/${item.orderId}`)
            .then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  type: "success",
                  message: "已申请退款!",
                });
                this.getOrderList(this.curIndex);
              } else {
                this.$message({
                  type: "warning",
                  message: res.message,
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
          this.getOrderList(this.curIndex);
        });
    },
    //确定收货
    surereceive(item) {
      this.$confirm("是否确定收货，确认后不可修改?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let formdata = new FormData();
          formdata.append("id", item.id);
          this.https.post("/api/front/order/take", formdata).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "已确认收货!",
              });
              this.getOrderList(this.curIndex);
            } else {
              this.$message({
                type: "warning",
                message: res.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
          this.getOrderList(this.curIndex);
        });
    },
    //评价订单
    commentOreder(item) {
      console.log(item);
      this.showComment = true;
      this.currentGoods = item;
    },
    canceComment() {
      this.showComment = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.index {
  width: 1200px;
  margin: 0 auto;
  background-color: #f5f6f7;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  .menu {
    width: 200px;
    background-color: #fff;
    li {
      line-height: 60px;
      border-bottom: 1px solid #ccc;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .main {
    width: 980px;
    background-color: #fff;
    text-align: left;
    padding: 0 25px;
    .mainTitle {
      line-height: 60px;
      border-bottom: 1px dashed blue;
      span {
        font-size: 22px;
      }
    }
    .menuTitle {
      display: flex;
      justify-content: space-between;
      padding: 0 px;
      .menuTitleBox {
        display: flex;
        height: 40px;
        align-items: center;
        li {
          padding: 0 15px;
          line-height: 20px;
          color: #999;
          border-right: 1px solid #ccc;
          cursor: pointer;
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            border-color: transparent;
          }
        }
        .actived {
          color: orange;
        }
      }
    }
  }
  .orders {
    padding-top: 20px;
    .orderItem {
      border: 1px solid #ccc;
      margin-bottom: 20px;
      .orderStatus {
        line-height: 40px;
        color: #999;
        font-size: 18px;
        padding: 20px 20px 0;
      }
      .orderInfo {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 15px;
        border-bottom: 1px solid #ddd;
        .left {
          display: flex;
          align-items: center;
          height: 20px;
          li {
            color: #666;
            padding: 0 15px;
            border-right: 1px solid #ccc;
            &:last-child {
              border-color: transparent;
            }
            &:first-child {
              padding-left: 0;
            }
          }
        }
        .right {
          color: #666;
          span {
            font-size: 26px;
          }
        }
      }
      .goods {
        display: flex;
        justify-content: space-between;
        .goodsItemBox {
          width: 600px;
        }
        .actions {
          display: flex;
          flex-direction: column;
          padding-right: 20px;
          padding-top: 20px;

          .el-button {
            width: 120px;
            margin-bottom: 15px;
            margin-left: 0 !important;
          }
        }
      }
      .actionBox {
        border-top: 1px dashed #eee;
        padding: 15px 20px;
        text-align: right;
        .el-button {
          margin-left: 15px;
        }
      }
    }
    .pinks {
      border-color: orange;

      .orderStatus {
        background-color: #fffaf7;
      }
      .orderInfo {
        border-bottom: 1px solid orange;
        background-color: #fffaf7;
      }
    }
    .greens {
      border-color: #b3c44e;
      .orderStatus {
        background-color: #fbfff7;
      }
      .orderInfo {
        border-bottom: 1px solid #cfeeab;
        background-color: #fbfff7;
      }
    }
  }
}
.goodsItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  img {
    width: 100px;
    height: 100px;
    margin-right: 15px;
  }
  .images {
    width: calc(100% - 120px);
    line-height: 26px;
    color: #999;
    .imagesItem {
      display: flex;
      justify-content: space-between;
    }
  }
}
.noData {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
::v-deep .el-form-item__content {
  display: flex;
  align-items: center;
  height: 40px;
}
</style>